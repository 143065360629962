<template>
  <div id="hiveGame" class="modal is-active" tabindex="-1" role="dialog">
    <div class="modal-background" />
    <div class="modal-card modal-lg" role="document">
      <header class="modal-card-head is-warning">
        <h2 v-if="roomId" class="modal-card-title">
          Join Hive #<span class="roomCode">{{ roomId }}</span><br><small>Enter the Hive code in the game.</small>
        </h2>
        <h2 v-else class="modal-card-title">
          Create Hive Game <span class="tag is-dark">PREMIUM</span><br><small>Choose your options.</small>
        </h2>
        <button style="align-self: flex-start;" class="delete" aria-label="close" @click="closeModal" />
      </header>
      <div v-if="!isConnected" class="messageBar">
        <p>Connecting...</p>
      </div>
      <div v-if="$store.getters.isPremium || $store.getters.hasNumber" class="modal-card-body main">
        <div v-if="openRooms.length > 0 && !roomId" class="content columns is-multiline is-paddingless" style="border-bottom: 1px dashed #ddd;">
          <div v-for="(rm, index) in openRooms" :id="'ROOM-'+rm.id" class="column is-2">
            <div class="panel panel-default">
              <div class="panel-heading">
                <a class="button is-small" @click.prevent="reopenRoom(rm)"><i class="ion ion-md-play" /></a> #{{ rm.room }} <a class="pull-right has-text-black" @click.prevent="closeRoom(rm.room)"><i class="fas fa-times" /></a>
              </div>
              <div class="panel-body">
                <p class="is-marginless">
                  <b>Difficulty:</b> {{ rm.difficulty == 1 ? 'Easy' : (rm.difficulty == 2 ? 'Medium' : (rm.difficulty == 3 ? 'Hard' : 'Extreme')) }}
                </p>
                <p class="is-marginless">
                  <b>Shuffle Words:</b> {{ rm.shuffle ? 'Yes' : 'No' }}
                </p>
                <p class="is-marginless">
                  <b>Show Words:</b> {{ rm.show_words ? 'Yes' : 'No' }}
                </p>
                <p class="is-marginless">
                  <b>Auto Play:</b> {{ rm.auto_play ? 'Yes' : 'No' }}
                </p>
                <p class="is-marginless">
                  <b>Hub Audio:</b> {{ rm.hub_audio ? 'Yes' : 'No' }}
                </p>
                <p class="is-marginless">
                  <b>Show Leagues:</b> {{ rm.show_leagues ? (rm.show_leagues == 9999 ? 'All' : 'Top ' + rm.show_leagues ) : 'Off' }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!roomId" id="createGame" class="container">
          <div class="columns is-multiline is-centered">
            <div class="content is-marginless column is-10" style="padding: 20px 0 0 0;">
              <h3>Word List: {{ listName }}</h3>
            </div>

            <div class="column is-10">
              <b-field horizontal label="Difficulty">
                <b-select id="inputEmail3" v-model="difficulty" placeholder="Difficulty" expanded>
                  <option value="1">
                    Easy
                  </option>
                  <option value="2">
                    Medium
                  </option>
                  <option value="3">
                    Hard
                  </option>
                  <option value="4">
                    Extreme
                  </option>
                </b-select>
              </b-field>

              <b-field horizontal label="No. words" message="The number of words to use in the hive game. Values 6 - 20 allowed.">
                <b-input
                  :value="wordCount"
                  type="number"
                  step="1"
                  max="20"
                  min="6"
                  @input="setWordCount"
                />
              </b-field>

              <b-field horizontal label="Shuffle?" message="Shuffle word list or use first words in order.">
                <b-switch v-model="shuffle" type="is-success">
                  <small v-if="shuffle">On</small><small v-else>Off</small>
                </b-switch>
              </b-field>

              <b-field horizontal label="Show word?" message="Show words on screen during Hive game.">
                <b-switch v-model="showWords" type="is-success">
                  <small v-if="showWords">On</small><small v-else>Off</small>
                </b-switch>
              </b-field>

              <b-field horizontal label="Autoplay?" message="The game advances on its own.">
                <b-switch v-model="autoPlay" type="is-success">
                  <small v-if="autoPlay">On</small><small v-else>Off</small>
                </b-switch>
              </b-field>
              <button class="button is-pulled-right" @click="playAudio('test', locale)">
                <i class="ion ion-md-play" />
              </button>
              <b-field horizontal label="Hub Audio?" message="Play word clips on hub or on all devices.">
                <b-switch v-model="hubAudio" type="is-success">
                  <small v-if="hubAudio">Hub</small><small v-else>All devices</small>
                </b-switch>
              </b-field>

              <b-field horizontal label="Show leagues?">
                <b-radio v-model="showLeagues" name="leagues" native-value="9999">
                  All
                </b-radio>
                <b-radio v-model="showLeagues" name="leagues" native-value="3">
                  Top 3
                </b-radio>
                <b-radio v-model="showLeagues" name="leagues" native-value="0">
                  Off
                </b-radio>
              </b-field>
              <p><small class="is-pulled-right">Results and leagues will still show all entries in the teacher hub.</small></p>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="wordIndex < 0 && status == 'started'">
            <div class="textCentered content is-clearfix">
              <!-- <h1>Waiting for players...</h1> -->
              <h2 class="hiveWord is-size-2">
                Players - use code #<span class="roomCode has-text-link">{{ roomId }}</span> to join the game.
              </h2>
            </div>
            <div v-if="members.length > 0" style="padding:20px content" class="is-clearfix columns">
              <div v-for="member in members" class="column is-3">
                <div class="playerPanel">
                  <AvatarView
                    v-if="member.avatar != null"
                    :key="'hiveav-'+member.id"
                    style="float:left; margin-right:10px;"
                    :uniq="'hiveuser-'+member.id"
                    :data="(member.avatar && member.avatar != '') ? JSON.parse(member.avatar) : {}"
                    scale-factor="0.5"
                  />
                  <div class="content is-marginless is-paddingless">
                    <h4 style="padding-top: 15px; margin-bottom: 0.2em;">
                      <b>{{ member.display_name || member.name }}</b>
                    </h4>
                    <p>@{{ member.username }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="wordIndex < words.length">
            <!--  && members.length > 0 -->
            <div class="textCentered">
              <div v-if="status == 'playing'" class="content">
                <!-- show question -->
                <button class="btn button is-danger pull-right" :disabled="!isConnected" @click.prevent="showResults">
                  Skip Word
                </button>
                <h1 v-if="showWords" class="hiveWord">
                  {{ words[wordIndex] }}
                </h1>
                <h1 v-else class="hiveWord">
                  Word {{ wordIndex + 1 }} / {{ words.length }}
                </h1>

                <h2 v-if="roundData">
                  {{ roundData.length }} / {{ members.length }} Answers
                </h2>
                <button class="button is-large is-warning is-centered" @click="playAudio(words[wordIndex], locale)">
                  <i class="ion ion-md-play" />
                </button>
              </div>

              <div v-if="status == 'started'" class="content">
                <!-- show question -->
                <button class="button is-large is-success is-centered" :disabled="!isConnected" @click="startGame()">
                  Start Game
                </button>
              </div>

              <div v-else-if="status == 'results'" class="content">
                <!-- show results -->
                <button class="btn button is-success pull-right" :disabled="(autoPlay && !forceShowButton && !isConnected ? 'disabled' : null)" @click.prevent="showLeague">
                  View Leaderboard
                </button>
                <h2 class="hiveWord">
                  {{ words[wordIndex] }}
                </h2>
                <table id="roundData" aria-describedby="classes_info" role="grid" class="table">
                  <tbody>
                    <tr v-for="(rnd, index) in roundData" role="row" :class="rnd.correct ? 'success' : 'danger'">
                      <td style="width:10px;">
                        <span v-if="rnd.correct">{{ index + 1 }}.</span>
                      </td><td>{{ rnd.name }} (@{{ rnd.username }})</td><td>{{ parseInt(rnd.score).toLocaleString() }}pts</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else-if="status == 'league'" class="content">
                <!-- show league -->
                <button v-if="wordIndex < (words.length - 1)" class="btn button is-success pull-right" :disabled="(autoPlay && !forceShowButton && !isConnected ? 'disabled' : null)" @click.prevent="nextQuestion">
                  Next Word
                </button>
                <button v-else class="btn button is-danger pull-right" :disabled="!autoPlay && !isConnected" @click.prevent="endGame">
                  End Game
                </button>
                <h2>League Table</h2>
                <table id="leagueData" aria-describedby="classes_info" role="grid" class="table is-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Player</th>
                      <th>Correct Answers</th>
                      <th>Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(rnd, index) in leagueData" role="row">
                      <td style="width:10px;">
                        {{ index + 1 }}.
                      </td>
                      <td>{{ rnd.name }} (@{{ rnd.username }})</td>
                      <td>{{ rnd.correctCount }} / {{ wordIndex + 1 }}</td>
                      <td>{{ parseInt(rnd.totalScore).toLocaleString() }}pts</td>
                    </tr>
                  </tbody>
                </table>
                <h2 v-if="wordIndex == words.length - 1">
                  Game finished
                </h2>
              </div>
            </div>
          </div>
          <div v-else class="content">
            <h2>No players</h2>
          </div>
        </div>
      </div>
      <div v-else class="text-center content" style="padding:10px;">
        <h2>Premium Feature</h2>
        <p>Upgrade to premium to use this feature.</p>
        <router-link v-if="isAdmin" to="/manage-subscription" class="btn button is-link" data-dismiss="modal" @click="closeModal">
          Upgrade
        </router-link>
      </div>
      <footer v-if="!roomId" class="modal-card-foot">
        <button class="button is-success" :disabled="!isConnected" @click.prevent="createRoom">
          Create Game
        </button>
      </footer>
      <footer v-else-if="wordIndex < 0 && status == 'started'" class="modal-card-foot">
        <button v-if="members.length > 0" class="btn button is-success" :disabled="!isConnected" @click.prevent="startGame">
          Start Game
        </button>
      </footer>
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal -->
</template>

<script>
// import QRCode from 'qrcode'
import Switches from 'vue-switches'
import io from 'socket.io-client'
import $ from 'jquery'
import { request } from '@/edshed-common/api'
import config from '@/config'
import AvatarView from '@/components/views/AvatarView'
import { Howl } from 'howler'

let socket = null

export default {
  name: 'HiveGame',
  components: {
    Switches,
    AvatarView
  },
  props: ['list', 'listName'],
  data (router) {
    return {
      socketURL: config.serverURI,
      roomId: null,
      difficulty: 4,
      shuffle: true,
      showWords: false,
      autoPlay: false,
      forceShowButton: false,
      buttonTimer: null,
      hubAudio: true,
      showLeagues: '9999',
      wordCount: '10',
      wordIndex: -1,
      status: 'started', // playing / results / league
      words: [],
      members: [],
      roundData: [],
      leagueData: [],
      openRooms: [],
      hasConnected: false,
      isConnected: false,
      locale: this.$store.state.user.locale
    }
  },
  computed: {
    nickname () {
      return this.$store.state.user.username
    },
    unansweredMembers () {
      // loop Through members. If there is no answer in roundData increment
      let count = 0
      for (let i = 0; i < this.members.length; i++) {
        let answered = false
        for (let j = 0; j < this.roundData.length; j++) {
          if (this.roundData[j].username === this.members[i].username) {
            answered = true
          }
        }
        if (!answered) {
          count = count + 1
        }
      }
      return count
    },
    isAdmin () {
      return (this.$store.state.user.school.admin === 1)
    }
  },
  mounted () {
    this.$nextTick(() => {
      const secure = this.socketURL.indexOf('https') === 0

      socket = io(this.socketURL, { secure, query: 'token=' + this.$store.state.token }) // , transports: ['websocket', 'polling']

      const that = this
      socket.on('connect', function () {
        console.log('connected to websocket')
        that.isConnected = true
        that.sendNickname()
        if (that.hasConnected) {
          console.log('REJOINING ROOM 2 ' + that.roomId)
          // setTimeout(function() { rejoinRoom()}, 1000)
        } else {
          that.hasConnected = true
        }
      })
      socket.on('disconnect', function () {
        that.isConnected = false
        console.log('disconnected from websocket')
      })
      socket.on('reconnect', function () {
        console.log('REJOINING ROOM ' + that.roomId)
        socket.emit('rejoin-room', that.roomId, null)
      })
      socket.on('room-status', function (msg) {
        console.log(msg)
        if (msg.round) {
          that.wordIndex = parseInt(msg.round)
        }
        that.status = msg.status || 'started'
        if (that.status === 'results' || that.status === 'league') {
          that.startButtonTimer()
        }
      })
      socket.on('room created', function (msg) {
        console.log('room created recieved ' + msg.room)
        that.roomId = msg.room
        that.words = msg.words.split(',')
      })
      socket.on('room-members', function (msg) {
        that.setMembers(msg.members)
      })
      socket.on('start game', function (msg) {
        console.log('msg: ' + msg.msg)
      })
      socket.on('show-question', function (qnum) {
        console.log('msg: ' + qnum)
        that.wordIndex = qnum.question
        that.status = 'playing'
        that.forceShowButton = false
        clearTimeout(that.buttonTimer)
        if (this.autoPlay) {
          this.startButtonTimer()
        }
        if (that.hubAudio) {
          that.playAudio(that.words[that.wordIndex], that.locale)
        }
      })
      socket.on('ping', function () {
        console.log('ping')
      })
      socket.on('pong', function () {
        console.log('pong')
      })
      socket.on('error', function (msg) {
        console.log('error: ' + msg)
        if (msg === 'Authentication error') {
          that.closeModal()
        }
        if (process.env.NODE_ENV === 'development') {
          alert(msg)
        }
      })
      socket.on('round-data', function (msg) {
        console.log('round: ' + msg.round)
        console.log('round data: ' + msg.roundData)
        console.log('league data: ' + msg.leagueData)

        that.roundData = msg.roundData || []
        that.leagueData = msg.leagueData || []
      })
      socket.on('open-rooms', function (rooms) {
        console.log('rooms: ' + rooms)
        that.openRooms = []
        for (let i = 0; i < rooms.length; i++) {
          console.log(rooms[i].list_ident)
          console.log(that.list)
          if (rooms[i].list_ident === that.list) {
            that.openRooms.push(rooms[i])
          }
        }
      })
    })
  },
  destroyed () {
    console.log('disconnect')
    socket.disconnect()
  },
  methods: {
    playAudio (word, locale) {
      console.log(word)
      const filename = 'https://files.edshed.com/audio/dictionary/' + locale + '/' + word[0].toUpperCase() + '/mp3/' + word.toLowerCase() + '.mp3'
      const oggFile = 'https://files.edshed.com/audio/dictionary/' + locale + '/' + word[0].toUpperCase() + '/ogg/' + word.toLowerCase() + '.ogg'
      // if (this.listData.is_phonics === 1) {
      //   var letr = word.toLowerCase()
      //   if (letr === 'c') {
      //     letr = 'k'
      //   }
      //   filename = 'https://files.edshed.com/audio/dictionary/' + this.listData.locale + '/PHONICS/mp3/' + letr + '.mp3'
      //   oggFile = 'https://files.edshed.com/audio/dictionary/' + this.listData.locale + '/PHONICS/ogg/' + letr + '.ogg'
      // }
      // var locale = this.listData.locale
      const that = this
      $.get(filename)
        .done(function () {
        // exists
        // var audio = new Audio(filename)
        // audio.play()
          const sound = new Howl({
            src: [oggFile, filename]
          })

          sound.play()
        }).fail(function () {
          // does not exists
          that.playGCWord(word, locale)
        })
    },
    playGCWord (word, locale) {
      const filename = 'https://api.edshed.com/audio/' + locale + '/' + word.toLowerCase() + '.mp3'
      const oggFile = 'https://api.edshed.com/audio/' + locale + '/' + word.toLowerCase() + '.ogg'
      console.log('GC: ' + filename)
      const that = this
      $.get(filename)
        .done(function () {
          $.get(oggFile)
            .done(function () {
              // exists
              // var audio = new Audio(filename)
              // audio.play()
              that.sound = new Howl({
                src: [oggFile, filename],
                onloaderror: () => {
                  that.speakWord(word)
                }
              })

              that.sound.play()
            }).fail(function () {
            // does not exists
              that.speakWord(word)
            })
        }).fail(function () {
          // does not exists
          const utterance = new SpeechSynthesisUtterance(word)
          const voices = window.speechSynthesis.getVoices()

          for (let i = 0; i < voices.length; i++) {
            if (voices[i].lang === locale || voices[i].lang === locale.replace('_', '-')) {
              utterance.voice = voices[i]
              break
            }
          }

          window.speechSynthesis.speak(utterance)
        })
    },
    setWordCount (ev) {
      this.wordCount = ev
    },
    rejoinRoom () {
      socket.emit('rejoin-room', this.roomId, this.wordIndex)
    },
    setMembers (membersArray) {
      const m = []
      const me = '' + this.nickname
      for (let i = 0; i < membersArray.length; i++) {
        /*
        console.log('member: ' + membersArray[i].username)
        console.log('me: ' + this.nickname)
*/
        if (membersArray[i].username !== me) {
          m.push(membersArray[i])
        }
      }
      this.members = m
    },
    createRoom () {
      socket.emit('create room', { list: this.list, difficulty: this.difficulty, showWords: this.showWords, shuffle: this.shuffle, wordCount: this.wordCount, autoPlay: this.autoPlay, hubAudio: this.hubAudio, showLeagues: this.showLeagues })
    },
    sendNickname () {
      // socket.emit('send-nickname', this.nickname)
    },
    getMembers (room) {
      socket.emit('get-members', this.roomId)
    },
    startGame () {
      this.wordIndex = 0
      this.status = 'playing'

      socket.emit('start-game', this.roomId)

      if (this.autoPlay) {
        this.startButtonTimer()
      }
      if (this.hubAudio) {
        this.playAudio(this.words[this.wordIndex], this.locale)
      }
    },
    showResults () {
      console.log('show results')
      // socket.emit('goto-waiting', this.roomId)
      socket.emit('show-results', this.roomId)
      this.status = 'results'
    },
    showLeague () {
      socket.emit('show-league', this.roomId)
      // get league data
      // this.leagueData = []

      // request('GET', 'hives/' + this.roomId + '/league', null)
      //   .then(response => {
      //     var data = response.data

      //     console.log(data)

      //     if (data.error) {
      //       this.response = data.error
      //       return
      //     }

      //     // update user
      //     if (data.league) {
      //       this.leagueData = data.league
      //     }
      //     this.status = 'league'
      //   })
      //   .catch(error => {
      //     console.log(error)
      //   })
    },
    closeRoom (roomId) {
      // $('#ROOM-' + roomId).remove()
      this.openRooms = this.openRooms.filter(r => r.room != roomId)
      // tell the server...
      socket.emit('close-room', roomId)
    },
    reopenRoom (roomData) {
      console.log('reopenning' + roomData.id)
      console.log(roomData)
      this.roomId = roomData.room
      this.difficulty = parseInt(roomData.difficulty)
      this.shuffle = (parseInt(roomData.shuffleWords) === 1)
      this.showWords = (parseInt(roomData.showWords) === 1)
      this.autoPlay = (parseInt(roomData.auto_play) === 1)
      this.hubAudio = (parseInt(roomData.hub_audio) === 1)
      this.showLeagues = parseInt(roomData.show_leagues || 9999)
      this.words = roomData.words.split(',')
      let round = parseInt(roomData.latestIndex) || null
      this.locale = roomData.locale
      if (round == null && roomData.status === 'started') {
        round = 0
        this.wordIndex = 0
      } else if (round !== null) {
        round = round + 1
        this.wordIndex = round
      }

      socket.emit('rejoin-room', this.roomId, round)
    },
    nextQuestion () {
      this.status = 'playing'
      this.wordIndex = this.wordIndex + 1
      this.roundData = []
      this.forceShowButton = false
      socket.emit('start-question', this.roomId, this.wordIndex)
      if (this.autoPlay) {
        this.startButtonTimer()
      }
      if (this.hubAudio) {
        this.playAudio(this.words[this.wordIndex], this.locale)
      }
    },
    endGame () {
      this.wordIndex = this.wordIndex + 1
      socket.emit('end-game', this.roomId)
    },
    closeAndQuit () {
      // this.endGame()
      this.closeModal()
    },
    closeModal () {
      this.$emit('hideModal')
    },
    startButtonTimer () {
      this.forceShowButton = false
      clearTimeout(this.buttonTimer)
      this.buttonTimer = setTimeout(this.enableButtons, 10000)
    },
    enableButtons () {
      this.forceShowButton = true
    }
  }
}
</script>
<style scoped>

  .playerPanel {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    display:flex;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  h2 small {
    color: black;
  }

  .modal-card.modal-lg {
    width: calc(100vw - 20px);
    max-height: calc(100vh - 20px);
    height: calc(100vh - 20px);
  }

  @media screen and (max-width: 769px) and (orientation:portrait) {
    .modal-card.modal-lg {
      height: calc(100vh - 120px);
    }
  }

  .textCentered {
    text-align: center;
  }

  .roomCode {
    /* color: #06a0dc; */
    text-decoration: underline;
    font-size: 160%;
    font-weight: bold;
  }

  .hiveUserCard {
    border: 1px solid #ccc;
    height: 60px;
    background: #fafafa;
  }

  .hiveWord {
    font-size: 5em;
    font-family: 'OpenDyslexicAltaRegular';
  }

  .messageBar {
    text-align: center;
    color: white;
    background-color: #454545;
  }

  /* .modal.is-active .modal-dialog {
    width:95%;
    margin: 20px auto 10px;
    min-height:calc(100% - 30px);
  } */

  /* .modal-content {
    width: 100%;
    height: calc(100vh - 40px);
    max-height: calc(100vh - 40px);
  }
  .modal-body form {
    display: block;
  } */

  #roundData, #leagueData {
    text-align: left;
    font-size: 1.2em;
  }

  .modal-card-head.is-warning {
    color: black;
    background-color: #ffdd57;
  }
  .modal-card-head.is-warning {
    border-bottom: 1px solid #ffdd57;
  }
  .modal-card-head.is-primary {
    color: white;
    background-color: #ff3860;
  }

  .modal-card-head.is-primary .modal-card-title {
    color: white;
  }

  .tabs.is-toggle #typeSelection li.is-active.is-warning a {
    color: black;
    background-color: #ffdd57;
    border-color: #ffdd57;
  }

  .modal-card-head.is-primary .modal-card-title {
    color: white;
  }
</style>
