<template>
  <section class="section">
    <div class="row center-block">
      <div class="col-md-12">
        <div class="box box-info">
          <div class="box-header with-border content">
            <h2>
              Hive Game
              <span class="tag is-dark">PREMIUM</span>
            </h2>
          </div>
          <!-- /.box-header -->
          <div class="box-body">
            <div v-if="$store.getters.isPremium || $store.getters.hasNumber">
              <div>
                <b-table
                  v-if="hiveData"
                  :data.sync="hiveData.league"
                  :paginated="true"
                  :per-page="10"
                  :mobile-cards="true"
                  :striped="true"
                  :selectable="false"
                  :sort="false"
                >
                  <template slot-scope="props">
                    <b-table-column field="position" label="#">
                      {{ props.index + 1 }}.
                    </b-table-column>
                    <b-table-column field="name" label="Player">
                      {{ props.row.school_id || !props.row.display_name ? props.row.name : props.row.display_name }}
                    </b-table-column>
                    <b-table-column field="correct" label="Correct">
                      {{ props.row.correctCount }} / {{ hiveData.words.split(',').length }}
                    </b-table-column>
                    <b-table-column field="score" label="Score">
                      {{ parseInt(props.row.totalScore).toLocaleString() }}
                    </b-table-column>
                    <b-table-column field="actions" label="">
                      <router-link v-if="props.row.school_id" :to="'/pupils/' + props.row.username" tag="button" class="button is-link is-pulled-right">
                        View Pupil
                      </router-link>
                      <span v-else class="tag">Not a Pupil</span>
                    </b-table-column>
                  </template>
                  <template slot="empty">
                    <section class="section">
                      <div class="content has-text-grey has-text-centered content">
                        <p>
                          <b-icon
                            custom-class="far"
                            pack="fa"
                            icon="frown"
                            size="is-large"
                          />
                        </p>
                        <p>Nothing here.</p>
                      </div>
                    </section>
                  </template>
                </b-table>
              </div>
              <div>
                <!-- A grid of pupils vs word with answers -->
                <b-table
                  v-if="hiveData"
                  :data.sync="uniqueUsers"
                  :paginated="true"
                  :per-page="10"
                  :mobile-cards="true"
                  :striped="true"
                  :selectable="false"
                  :sort="false"
                >
                  <template slot-scope="props">
                    <b-table-column field="position" label="Player" class="is-warning">
                      {{ answersData[props.row].school_id ? answersData[props.row].name : answersData[props.row].display_name }}
                    </b-table-column>
                    <b-table-column v-for="(w, index) in hiveData.words.split(',')" :key="'words-'+index" field="name" :label="w" :class="wasCorrect(props.row, w) ? 'is-success' : 'is-danger'">
                      {{ answerGivenFor(props.row, w) }}
                    </b-table-column>
                  </template>
                  <template slot="empty">
                    <section class="section">
                      <div class="content has-text-grey has-text-centered content">
                        <p>
                          <b-icon
                            custom-class="far"
                            pack="fa"
                            icon="frown"
                            size="is-large"
                          />
                        </p>
                        <p>Nothing here.</p>
                      </div>
                    </section>
                  </template>
                </b-table>
              </div>
            </div>
            <div v-else class="col-sm-12 text-center content">
              <h2>Premium Feature</h2>
              <p>Upgrade to premium to use this feature.</p>
              <router-link v-if="isAdmin" to="/manage-subscription" class="btn button is-link" data-dismiss="modal">
                Upgrade
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <HiveGame :list="$route.params.ident" :listName="listData.title" v-if="listData && showHiveModal" v-on:hideModal="hideHive" /> -->
  </section>
</template>
<script>
import { request } from '@/edshed-common/api'
import HiveGame from '@/components/views/components/HiveGame'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'HiveDetails',
  components: {
    HiveGame
  },
  data (router) {
    return {
      showModal: false,
      hiveData: [],
      answersData: null,
      uniqueUsers: [],
      response: ''
    }
  },
  computed: {
    isAdmin () {
      return (this.$store.state.user.school.admin === 1)
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getHiveData()
    })
  },
  methods: {
    getHiveData () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'hives/' + this.$route.params.id, null)
        .then((response) => {
          const data = response.data
          this.hiveData = data.hive
          if (!this.hiveData || this.hiveData.length === 0) {
            this.response = 'No Hives'
          }
          this.processAnswersData()
          // console.log('readerData: ' + this.readerData)
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()
          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    processAnswersData () {
      let uns = []

      for (let i = 0; i < this.hiveData.data.length; i++) {
        uns.push(this.hiveData.data[i].username)
      }
      uns = _.uniq(uns)
      this.uniqueUsers = uns
      const obj = {}

      for (let j = 0; j < uns.length; j++) {
        obj[uns[j]] = {}
      }
      console.log(obj)
      for (let k = 0; k < this.hiveData.data.length; k++) {
        obj[this.hiveData.data[k].username].name = this.hiveData.data[k].name
        obj[this.hiveData.data[k].username].display_name = this.hiveData.data[k].display_name ? this.hiveData.data[k].display_name : this.hiveData.data[k].name
        obj[this.hiveData.data[k].username].school_id = this.hiveData.data[k].school_id
        obj[this.hiveData.data[k].username][this.hiveData.data[k].word] = { correct: this.hiveData.data[k].correct, answerGiven: this.hiveData.data[k].answer_given }
      }

      this.answersData = obj
    },
    wasCorrect (username, word) {
      if (this.answersData[username][word] && parseInt(this.answersData[username][word].correct) === 1) {
        return true
      } else {
        return false
      }
    },
    answerGivenFor (username, word) {
      if (this.answersData[username][word] && this.answersData[username][word].answerGiven) {
        return this.answersData[username][word].answerGiven
      } else {
        return ''
      }
    },
    localeDate (dt) {
      const m = moment(dt)
      return m.format('DD/MM/YYYY HH:mm')
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    }
  }
}
</script>
